import React from "react";

function MissVis()
{
    return(
        <div className="mis-vis">
            <Vision/>
            <Mission/>
        </div>
    )
}

const Vision =()=>
{
    return(
        <div className="box-shadow">
            <h3>Vision</h3>
            <p>
                SM Consulting’s vision is to be leaders in offering
effective business solutions in and around South Africa
to individuals and businesses whilst maintaining a
personal and reliable relationship with each client.
            </p>
        </div>
    );
}

const Mission =()=>
{
    return(
        <div className="box-shadow">
            <h3>Mission</h3>
            <p>
                To provide the highest level of professional services, to meet our clients’ needs through mutual financial success by maintaining integrity and honesty in everything we do. To also provide for the development, growth and accountability of our team members by offering opportunities to achieve personal and professional success.
            </p>
        </div>
    );
}

export default MissVis;