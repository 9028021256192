import React from "react";
import reg from "../img/icons/reg.png";
import acc from "../img/icons/acc.png";
import tax from "../img/icons/tax.png";
import pay from "../img/icons/pay.png";
import { Link } from "react-router-dom";

function Services()
{
    return(
        <article className="services sect" id="services">
            <div className="services-container sect">
                <h2>Services</h2>
                <p>
                    We aim to provide the best solution for our clients. Trust that your business is in very 
                    skilled and highly qualified professional hands.
                </p>
                <div className="serv-cards">
                    <Registration/>
                    <Taxation/>
                    <Accounting/>
                    <Payroll/>
                </div>
                <Link to="/services">
                    <button className="more-serv">See More</button>
                </Link>                
            </div>            
        </article>
    );
}

export default Services;

const Registration =()=>
{
    return(
        <div className="serv-card box-shadow">
            <img src={reg} alt=""/>
            <h4>Registration</h4>
            <p>
                <ul>
                    <li>Company Registration</li>
                    <li>PAYE</li>
                    <li>Income Tax</li>
                    <li>
                        <Link to="/services">
                            More
                        </Link>
                    </li>
                </ul>
            </p>
        </div>
    );
}

const Taxation =()=>
{
    return(
        <div className="serv-card box-shadow">
            <img src={tax} alt=""/>
            <h4>Taxation</h4>
            <p>
                <ul>
                    <li>Company Registration</li>
                    <li>PAYE</li>
                    <li>Income Tax</li>
                    <li>
                        <Link to="/services">
                            More
                        </Link>
                    </li>
                </ul>
            </p>
        </div>
    );
}

const Accounting =()=>
{
    return(
        <div className="serv-card box-shadow">
            <img src={acc} alt=""/>
            <h4>Acounting</h4>
            <p>
                <ul>
                    <li>Company Registration</li>
                    <li>PAYE</li>
                    <li>Income Tax</li>
                    <li>
                        <Link to="/services">
                            More
                        </Link>
                    </li>
                </ul>
            </p>
        </div>
    );
}

const Payroll =()=>
{
    return(
        <div className="serv-card box-shadow">
            <img src={pay} alt=""/>
            <h4>Payroll</h4>
            <p>
                <ul>
                    <li>Company Registration</li>
                    <li>PAYE</li>
                    <li>Income Tax</li>
                    <li>
                        <Link to="/services">
                            More
                        </Link>
                    </li>
                </ul>
            </p>
        </div>
    );
}