import React from "react";


export default function FormSuccess()
{
    return(
        <div className="sect">
            <h1>
                Thank you! Your form has been successfully sent.
            </h1>
        </div>
    );
}