import React from "react";
import menu from "../img/icons/icons8-menu.svg";
import styles from "../css/menu-collapse.module.css";

class CollapsibleMenu extends React.PureComponent
{
    constructor(props)
    {
        super(props);
        this.state = {isCollapsed: false};
        this.toggleMenu = this.toggleMenu.bind(this);

    }
    

    toggleMenu()
    {
        const state = this.props.menuState;
        this.setState({isCollapsed: !state});
        // this.setState({this.state.isCollapsed: !this.state.isCollapsed});
        console.log("Menu clicked!!"+state);
        
    }


    render()
    {
        const hu = this.props.menuState;
        console.log(hu);

        return(
            <img src={menu} alt="" className={styles.menu_icon} onClick={this.toggleMenu}/>
        );
    }
}

export default CollapsibleMenu;


