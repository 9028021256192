import React from "react";
import hero from "../img/hero.jpeg";
import hero_text from "../img/hero-text.png";

function Hero()
{
    return(
        <article id="hero"> 
            <img src={hero} alt=""/>
            <div className="box-shadow">
                <h1 className="hero-txt">
                    <img src={hero_text} alt="" id="hero-txt"/>
                    {/* <span className="head--1">helping businesses</span>
                    <span className="head--2">of all sizes</span>
                    <span className="head--3">grow and</span>
                    <span className="head--4">reach</span>
                    <span className="head--5">their full</span>
                    <span className="head--6">potential</span> */}
                </h1>
            </div>
        </article>
    );
}

export default Hero;