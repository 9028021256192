import React from "react";
import serv1 from "../img/serv-1.jpeg";
import serv2 from "../img/serv-2.jpeg";
import ContactForm from "./ContactForm";

function ServicesPage()
{
    return(
        <>
            <article id="hero2" className="thm-block">
                <img src={serv1} alt=""/>
                <div>
                    <div className="section-hero-txt">
                        <h1>our Services</h1>
                        <p>
                            We aim to provide the best solution for our clients. Trust that your business is 
                            in very skilled and highly qualified professional hands.
                        </p>
                    </div>
                </div>
            </article>
            <ServicesList/>
            <article id="hero2" className="thm-block2">
            <img src={serv2} alt=""/>
            <div>
                <div className="section-hero-txt">
                    <p>
                        Our mission is to provide the highest level of professional services, to meet our clients’ needs through mutual financial success by maintaining integrity and honesty in everything we do. To also provide for the development, growth and accountability of our team members by offering
opportunities to achieve personal and professional success.
                    </p>
                </div>
            </div>
            </article>
            <Testimonials/>
            <ReachUs/>
        </>
    );
}

export default ServicesPage;

function ServicesList()
{
    return(
        <section className="serv-list">
            <div className="serv-list-item-container">
                <div className="serv-list-item">
                    <h2>
                        Registration
                    </h2>
                    <p>
                        SM Consulting ensures that your entity is correctly registered with the CIPC, 
                        our dedicated tax team provide pertinent advice as to what type of entity 
                        (individual/Trust/PTY LTD) is best suited for your start up and how your 
                        accounts should be structured to ensure tax optimisation. Below are the 
                        registrations that are included in our services:
                        <ul className="serv-list">
                            <li>Company registration (CIPC)</li>
                            <li>Non-profit organisation</li>
                            <li>Co-Operatives</li>
                            <li>PAYE</li>
                            <li>UIF and SDL</li>
                            <li>Income Tax</li>
                            <li>VAT</li>
                            <li>E-filling</li>
                            <li>COIDA</li>
                            <li>CSD (Central database)</li>
                            <li>CIDB (Construction grading)</li>
                            <li>Bargaining Council (Cleaning certificate)</li>
                        </ul>
                    </p>
                    <div className="serv-strip"></div>
                </div>
            </div>
            <div className="serv-list-item-container">
                <div className="serv-list-item">
                    <h2>
                        Taxation
                    </h2>
                    <p>
                        Our aim is to legally minimize the client’s tax burden. Proper planning of 
                        taxes are done on an ongoing basis to keep the tax bill to the minimum. Our 
                        service includes the monthly, bimonthly, annually and biannually submission 
                        of VAT. Please note that if you have not done any VAT submissions, we can 
                        help with outstanding returns, Input VAT is claimable for 5 years since date 
                        of invoice. <br/>
                        <br/>

                        Our accounting division consists of a dedicated tax team, including tax 
                        practitioners and tax professionals with special knowledge on VAT who have 
                        extensive experience in negotiating with SARS. If SARS has levied penalties 
                        and/or interest against VAT liability, our team can negotiate with SARS to 
                        waive penalties if you have a valid reason for not submitting returns or 
                        paying VAT. <br/>
                        <br/>
                        
                        Below is the thorough list of the services we provide in compliant with SARS:
                        <ul className="serv-list">
                            <li>VAT calculations and submissions</li>
                            <li>Income tax returns submissions (individuals and businesses)</li>
                            <li>Provisional tax submission</li>
                            <li>Company and Personal Tax advisory</li>
                            <li>Tax compliant certificate</li>
                        </ul>

                    </p>
                    <div className="serv-strip"></div>
                </div>
            </div>
            <div className="serv-list-item-container">
                <div className="serv-list-item">
                    <h2>
                        Accounting
                    </h2>
                    <p>
                        We assist with the preparation, review and compilation of Annual Financial 
                        Statements (“AFS”). AFS are prepared and presented in accordance with 
                        International Financial Reporting Standards for Small and Medium-sized 
                        Entities (IFRS for SME’s) and the requirements of the Companies Act of South 
                        Africa. Our Accountants are registered with SAIBA.<br/>
                        <br/>

                        Here is the list of what we provide your business with:
                        <ul className="serv-list">
                            <li>Monthly/ Bi-monthly management accounts/ Bookkeeping</li>
                            <li>Annual financial statements</li>
                            <li>Projections/ budgeting</li>
                            <li>Financial advisory</li>
                        </ul>
                    </p>
                    <div className="serv-strip"></div>
                </div>
            </div>
            <div className="serv-list-item-container">
                <div className="serv-list-item">
                    <h2>
                        Payroll
                    </h2>
                    <p>
                        Monthly payroll tasks include:
                        <ul className="serv-list">
                            <li>Generate and send payslips directly to employer</li>
                            <li>Submitting EMP201 returns to SARS monthly</li>
                            <li>Submitting EMP501 reconciliations to SARS for the periods ending August and February</li>
                        </ul>
                    </p>
                    <div className="serv-strip"></div>
                </div>
            </div>
            <div className="serv-list-item-container">
                <div className="serv-list-item">
                    <h2>
                        Other Services
                    </h2>
                    <p>
                        <ul className="serv-list">
                            <li>Close corporation conversion</li>
                            <li>Business name change</li>
                            <li>Business address change</li>
                            <li>Ownership changes</li>
                            <li>Tender documents</li>
                            <li>Business profile</li>
                        </ul>
                    </p>
                    <div className="serv-strip"></div>
                </div>
            </div>
        </section>
    );
}

function Testimonials()
{
    return(
        <div className="sect">
            <h2>
                Dont take it from us
            </h2>
            <ul>
                <li>
                    <p>
                        "SM Consulting has the best Accountung services in Joburg. Nice work on your SM Consulting. SM Consulting services are worth much more than I paid for." 
                        - B. James
                    </p>
                </li>
                <li>
                    <p>
                        "Thanks SM Consulting! You are really appreciated."
                        - D.A. Davids
                    </p>
                </li>
                <li>
                    <p>
                        "I have gotten at least 50 times the value from SM Consulting. SM Consulting 
                        was worth a fortune to my company."
                        - Gretna K.
                    </p>
                </li>
                <li>
                    <p>
                        "I like SM Consulting more and more each day because they make my life a 
                        whole lot easier. It's exactly what I've been looking for. They should be 
                        nominated for Taxa service of the year. SM Consulting is the most valuable 
                        business resource we have EVER paid for."
                        - Melissa Mabaso.
                    </p>
                </li>
            </ul>
        </div>
    );
}

function ReachUs()
{
    return(
        <section className="contact2 sect thm-block3">

            <section>
                <ContactForm/>
                <div>
                    <p>
                        Have questions about which accounting services may be right for you? Contact us.

                    </p>
                </div>
            </section>
        </section>
    );
}