import React from "react";
import Logo from "./Logo";
import Menu from "./Menu";
import CollapsibleMenu from "./Menu-Collapsible";

class Header extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = 
        {
            // screenWidth: this.getVW(),
            showCollapse: false
        };
    }

    /** getVW() obtains the viewport width of the device */
    getVW()
    {
        // console.log("Works"+window.innerWidth);
        let isShowCollapse = (window.innerWidth <= 720);
        if (isShowCollapse === true)
        {
            this.setState({showCollapse: isShowCollapse});
            console.log("YUP"+isShowCollapse);
            console.log("YES "+window.innerWidth);
        }
        else
        {
            this.setState({showCollapse: isShowCollapse});
            console.log("NOPE is all "+isShowCollapse);
        }
    }

    componentDidMount() 
    {
        window.addEventListener("resize", this.getVW.bind(this));
        this.getVW();
}

componentWillUnmount() {
    window.removeEventListener("resize", this.getVW.bind(this));
}


    render()
    {
        return(
            <header className="sticky header">
                <Logo/>
                {this.state.showCollapse ? <CollapsibleMenu menuState={this.state.showCollapse}/> : <Menu/>}
                
            </header>
        );
    }
}

export default Header;