import React from "react";
import logo from "../img/smc-logo.png";


// The SM logo component
function Logo()
{
    return(
        <a href="https://sm-consulting.co.za"><img src={logo} alt="The Official logo of SM Consulting" id="logo"/></a>
    );
}


export default Logo;