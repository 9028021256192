import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "../css/main.css";
import About from "./About";
import Contact from "./Contact";
import Footer from "./Footer";
import FormSuccess from "./FormSuccess";
import Header from "./Header";
import Hero from "./Hero";
import MissVis from "./MissVis";
import Services from "./Services";
import ServicesPage from "./ServicesPage";

function App() {
  return (
    <main className="app">
      <Router>
        <Header/>
        <Routes>
          <Route 
            path="/" 
            exact 
            element=
            {
              <>
                <Hero/>
                <MissVis/>
                <About/>
                <Services/>
                <Contact/>
              </>
            }
          />
          <Route path="/services" exact element={<ServicesPage/>}/>
          <Route path="/form-success" exact element={<FormSuccess/>}/>
        </Routes>
        <Footer/>
      </Router>
     
    </main>
  );
}

export default App;
