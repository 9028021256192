import React from "react";
import Logo from "./Logo";

function Footer()
{
    return(
        <footer>
            <div className="footer">
                <div id="footer-top">
                    <Logo/>
                    <div>
                        <h4>Contact</h4>
                        <ul>
                            <li>info@sm-consulting.co.za</li>
                            <li>+27 74 489 8091</li>
                        </ul>
                    </div>
                    <div>
                        <h4>Quick Links</h4>
                        <ul>
                            <li>About Us</li>
                            <li>Our Services</li>
                            <li>F.A.Q.</li>
                            <li>Privacy Policy</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id="footer-bot">SM Consulting © 2022</div>
        </footer>
    );
}

export default Footer;