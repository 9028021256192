import React from "react";

class ContactForm extends React.Component
{
    render()
    {
        return(
            <form className="myform box-shadow" name="contact" method="post" action="/form-success" netlify netlify-honeypot="bot-field" hidden>
                <input type="hidden" name="form-name" value="contact" />
                <p className="hidden">
                    <label>
                        Don’t fill this out if you’re human: <input name="bot-field" />
                    </label>
                </p>

                <div>
                    <label>First Name:
                        <input type="text" name="first-name" required/>
                    </label>
                    <label>Last Name:
                        <input type="text" name="last-name" required/>
                    </label>
                </div>
                <div>
                    <label>Email Address:
                        <input type="email" name="email" required/>
                    </label>
                    <label>Phone Number:
                        <input type="tel" name="tel" required/>
                    </label>
                </div>
                <div>
                    <label>Service of interest:
                        <select name="service-name" required>
                            <option selected disabled>Select your query</option>
                            <option>Registration</option>
                            <option>Taxation</option>
                            <option>Accounting</option>
                            <option>Payroll</option>
                            <option>Other Services</option>
                        </select>
                    </label>
                </div>
                <div>
                    <label>Message:
                        <textarea name="message" required></textarea>
                    </label>
                </div>
                <button className="submit-form" type="submit">submit</button>
            </form>
        );
    }
}

export default ContactForm;