import React from "react";

function About()
{
    return(
        <section className="about sect" id="about-us">
            <h2>About Us</h2>
            <p>
                SM Consulting is affiliated with the South African Institute for Business Accountants (SAIBA)
                and specialises in tailor making solution for business growth, with more than seven (7) years 
                of experience in the financial & tax sector, our results have proven to be of great value to 
                our clients, Providing the balance of personal interest and ability to meet a broad variety 
                of business needs at affordable prices. As a collective accounting firm that offers quality 
                services to our clients and investors, creating curated strategic plans are our passion. We 
                provide fast and reliable solutions for businesses. With the aim of helping businesses of all 
                sizes grow and reach their full potential.
            </p>
        </section>
    );
}

export default About;