import React from "react";
import ContactForm from "./ContactForm";
import mail from "../img/mail.png";
// import ContactForm2 from "./ContactForm2";

function Contact()
{
    return(
        <section className="contact sect" id="contact-us">
            <h2>Contact Us</h2>
            <p>
                Please leave a message for us and we'll get back to you as soon as possible.
            </p>
            <section>
                <ContactForm/>
                <aside>
                    <img src={mail} alt=""/>
                </aside>
            </section>
        </section>
    );
}

export default Contact;

