import React, { PureComponent } from "react";

class Menu extends PureComponent
{
    constructor()
    {
        super();
        this.state = {}
    }

    render()
    {
        return(
            <nav className="menu">
                <ul>
                    <a href="/"><li className="active">Home</li></a>
                    <a href="/#about-us"><li>About US</li></a>
                    <a href="/#services"><li>Services</li></a>
                    <a href="/#contact-us"><li>Contact Us</li></a>
                </ul>
            </nav>
        );
    }
}

export default Menu;